<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-card-title>
          <div class="d-flex justify-content-between">
            <h3> الصلاحيات</h3>
            <b-button variant="primary" @click="$router.back()"> <feather-icon icon="ArrowLeftIcon" /> </b-button>
          </div>
        </b-card-title>
        <b-card-sub-title>الصلاحيات حسب الأدوار</b-card-sub-title>
      </b-card-body>
      <b-card no-body class="border mx-1">
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon icon="LockIcon" size="18" />
            <span class="align-middle ml-50">الصلاحيات</span>
          </b-card-title>
        </b-card-header>
        <data-table :ep="`/roles/${id}`" :pagination="false" striped :fields="fields" dataPath="data.permissions">
          <template #cell(name)="data">
            <h5>{{ data.item.permission_name }}</h5>
          </template>
          <template #cell()="data">
            <b-form-checkbox :checked="data.item.sub_permission.includes(data.field.key)" :disabled="true" />
          </template>
        </data-table>
      </b-card>
    </b-card>
  </div>
</template>
    
<script>
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BCardHeader,
  BButton,
} from "bootstrap-vue";
import DataTable from "@/components/data-table/index.vue";

export default {
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    DataTable,
    BCardHeader,BButton
  },
  props: ["id"],
  data: () => {
    return {
      fields: [
        {
          key: "name",
          label: "الأدوار",
        },
        {
          key: "S",
          label: "إضهار",
        },
        {
          key: "C",
          label: "إضافة",
        },
        {
          key: "D",
          label: "حذف",
        },
        {
          key: "U",
          label: "تعديل",
        },
        {
          key: "O",
          label: "الصلاحيات أخرى ",
        },
      ],
    };
  },

  created() { },
};
</script>
    
<style>

</style>
    